import React from 'react';
// import PropTypes from 'prop-types';
// import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Main from '../components/main';
import Analytics from '../components/analytics';

const TheBoys = ({ pageContext }) => (
  <Layout>
    <Analytics lang={pageContext.region} pageUrl="the-boys" />
    <Main boys="true" region={pageContext.region} />
  </Layout>
);

export default TheBoys;
